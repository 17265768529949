export default {
  //A
  "All rights reserved": "All rights reserved",
  Admin: "Admin",
  Accepted: "Accepted",
  Arrived: "Arrived",
  "About the panel": "About the panel",
  "Adapted to your needs": "Adapted to your needs",
  "Arrange trips for your clients": "Arrange trips for your clients",
  Additionally: "Additionally",
  "Also driver's availability near the origin point of the trip":
    "Also driver's availability near the origin point of the trip",
  ACTION: "ACTION",
  ACCIÓN: "ACTION",
  Apply: "Apply",
  "Available balance": "Balance",
  AMOUNT: "AMOUNT",
  "AGREGADO/RESTADO": "ADD/CUT",
  "ADD/CUT": "ADD/CUT",
  "Add to payroll": "Add to payroll",
  Active: "Active",
  Activo: "Active",
  "Activate overdraft": "Activate overdraft",
  "Activate user": "Activate user",
  Approved: "Approved",
  All: "All",
  Assignations: "Assignations",
  "Additionally, our monitoring team is always at willingness to deal with any incident":
    "Additionally, our monitoring team is always at willingness to deal with any incident",
  "AGREGADO/RESTADO (USUARIO)": "ADD/CUT (USER)",
  "ADD/CUT (USER)": "ADD/CUT (USER)",
  "Also driver's availability near the origin point of the parcel":
    "Also driver's availability near the origin point of the parcel",
  Accept: "Accept",
  Approve: "Approve",
  and: "and",
  automated_payments: "Automated payments",
  automated_zelle: "Automated zelle",
  automated_pago_movil: "Automated pago móvil",
  api_credentials_generate_token: "Generate token",
  api_credentials_is_allowed_corporate_api_partner:
    "Global access to corporate api partners allowed",
  api_credentials_is_corporate_api_partner:
    "Singular access to corporate api partners allowed",
  api_credentials_is_allowed_corporate_shipment_api:
    "Singlular access to corporate api partners shipment api",
  api_credentials: "Credentials",
  api_credentials_tooltip:
    "In this view you can review, and edit edit your API credentials",
  api_credentials_active_api: "Permissions and access active in the API",
  api_credentials_active_api_tooltip:
    "In this section you can review permissions and access active in the API",
  api_permissions_table_header: "Permission",
  api_active_table_header: "Active",
  api_credentials_is_allowed_corporate_api_partner_tooltip:
    "This permission indicates if Ridery has allowed access to its API to its API Partners",
  api_credentials_is_corporate_api_partner_tooltip:
    "This permission indicates if Ridery has allowed access to the Corporate Panel to its API Partners API",
  api_credentials_is_allowed_corporate_shipment_api_tooltip:
    "This permission indicates whether the Corporate Panel has particular access to the Ridery API for API Partners for Shipments",
  api_credentials_token: "Token",
  api_credentials_token_tooltip:
    "In this section you can review and generate a token used to access the API for Ridery Api Partners",
  api_credentials_token_label: "Token for auth in API",
  api_credentials_webhook_title: "Webhook",
  api_credentials_webhook_title_tooltip:
    "In this section, you can set up a Webhook to receive notifications about status changes of trips/shipments you have made as an API Partner",
  api_credentials_send_webhook: "Send Webhook",
  api_credentials_send_webhook_deactivate: "Deactivate Webhook",
  api_documentation: "Documentation",
  api_documentation_tooltip:
    "In this view you can review the documentation for API Partners",
  //B
  Basic: "Basic",
  Balance: "Balance",
  "Back to the panel": "Back to the panel",
  By: "By",
  "Book you work trips": "Book you work trips",
  "Book a demonstration with our team": "Book a demonstration with our team",
  "Balance top-up request": "Balance top-up request",
  "By admin": "By admin",
  BALANCE: "BALANCE",
  "By clicking here you can refresh the map":
    "By clicking here you can refresh the map",
  "By clicking you can refresh the nearby providers in the map":
    "By clicking you can refresh the nearby providers in the map",
  Brand: "Brand",
  "BALANCE INICIAL": "INITIAL BALANCE",
  "INITIAL BALANCE": "INITIAL BALANCE",
  "BALANCE FINAL": "FINAL BALANCE",
  "FINAL BALANCE": "FINAL BALANCE",
  "BALANCE INICIAL (CORPORATIVO)": "INITIAL BALANCE (CORPORATE)",
  "INITIAL BALANCE (CORPORATE)": "INITIAL BALANCE (CORPORATE)",
  "BALANCE FINAL (CORPORATIVO)": "FINAL BALANCE (CORPORATE)",
  "FINAL BALANCE (CORPORATE)": "FINAL BALANCE (CORPORATE)",
  bulk: {
    unitary: "Unitary",
    bulk: "Bulk",
  },
  //C
  "Create a new Trip": "Create a new Trip",
  Corporate: "Corporate",
  clear: "limpiar",
  Completed: "Completed",
  Cancelled: "Cancelled",
  Coming: "Coming",
  "Cancelled by user": "Cancelado por usuario",
  "Corporate Panel": "Corporate Panel",
  "Corporate Panels": "Corporate panels",
  "Corporate Panels Totals": "Total corporate panels",
  Contact: "Contact",
  "Control your spending": "Control you spending",
  Contactverb: "Contact",
  "Contact form": "Contact form",
  "Can I book a trip or make reservations for another person?":
    "Can I book a trip or make reservations for another person that's not in my panel?",
  "Create trip": "Create trip",
  "Current and scheduled trips": "Current and scheduled trips",
  CONDUCTOR: "PROVIDER",
  COST: "COST",
  COSTO: "COST",
  "Cancel trip": "Cancel trip",
  Cancel: "Cancel",
  "Charge of this trip": "Charge of this trip",
  Card: "Card",
  "CORREO ELECTRÓNICO": "EMAIL",
  "Contact corporativo@ridery.app requesting the invoice of the recharge previously made, or failing that, to the fill out the payment form select ''YES'' in the field of invoice.":
    "Write an email to corporativo@ridery.app requesting the invoice for the recharge previously made, otherwise, when filling out the payment form, select ''YES'' in the invoice field.",
  "Create a new Parcel": "Create a new Parcel",
  "Current and scheduled parcel": "Current and scheduled parcels",
  Clear: "Clear",
  copy_trip_details: "Copy trip details link",
  copy_trip_details_success: "Trip details link copied successfully",
  copy_parcel_details: "Copy parcel details link",
  copy_parcel_details_success: "Parcel details link copied successfully",
  CorporatePanels: "Corporate panels",
  corporate_master_assign: "Master panel top up",
  CorporatePanel: "Corporate panel",
  contact_us: "Contact us",
  copy_to_clipboard: "Copy to clipboard",
  copy_to_clipboard_success: "Copy to clipboard success",
  Credentials: "Credentials",
  //D
  Dashboards: "Dashboards",
  "Destination address": "Destination address",
  "Date and Time": "Date and Time",
  "Dirección de origen": "Origin address",
  "Dirección de destino": "Destination address",
  DISTANCIA: "DISTANCE",
  DURACIÓN: "DURATION",
  "DATE AND TIME": "DATE AND TIME",
  "DIRECCIÓN DE ORIGEN": "ORIGIN ADDRESS",
  "DIRECCIÓN DE DESTINO": "DESTINATION ADDRESS",
  "DESTINATION ADDRESS": "DESTINATION ADDRESS",
  "Do you want to cancel the trip?": "Do you want to cancel the trip",
  "Date range": "Date range",
  "Deactivate overdraft": "Deactivate overdraft",
  "Date issued": "Date issued",
  "Date paid": "Date paid",
  Discount: "Discount",
  Details: "Details",
  "Driver's app certification": "Driver's app certification",
  Delete: "Delete",
  "Driver Waiting": "Driver Waiting",
  Documentation: "Documentation",
  //E
  Email: "Email",
  "Enter your email and we'll send you instructions to reset your password":
    "Enter your email and we'll send you instructions to reset your password",
  "Email sent": "Email sent",
  "Enter your new password": "Enter your new password",
  "Error creating trip": "Error creating trip",
  Export: "Export",
  EMAIL: "EMAIL",
  "Eliminate user": "Eliminate user",
  "Efficiency in the execution of your budget.":
    "Guarantee efficiency in the execution of your budget.",
  //F
  "Forgot Password?": "Forgot Password?",
  FINANCIAL: "FINANCIAL",
  FAQ: "FAQ",
  FAQ2: "FAQ",
  "Fecha y hora": "Date and time",
  "FECHA Y HORA": "DATE AND TIME",
  "Find your workers inside Ridery's app to add them to your payroll":
    "Find your workers inside Ridery's app to add them to your payroll",
  "For your safety": "For your safety",
  "For your comfort": "For your comfort",
  "For increased efficiency": "For increased efficiency",
  fullName: "Full name",
  //G
  GoBack: "Go back",
  "Get quote": "Get quote",
  "Get quick quote": "Get quick quote",
  "Go to page": "Go to page",
  "Good Morning!": "Good Morning!",
  "Good Afternoon!": "Good Afternoon!",
  "Good Evening!": "Good Evening!",
  //H
  History: "History",
  "History ": "History ",
  "How is a trip's fare calculated?": "How is a trip's fare calculated?",
  "How do I pay for my business trips?": "How do I pay for my business trips?",
  "How does Ridery take care of the safety of its passengers?":
    "How does Ridery take care of the safety of its passengers?",
  "How do I receive invoices for my company's trips?":
    "How do I receive invoices for my company's trips?",
  "How can I control my payroll expenses?":
    "How can I control my payroll expenses?",
  "How to request the invoice prior to recharging?":
    "How to request the invoice prior to recharging?",
  "How does Ridery take care of the safety of its passengers?":
    "How does Ridery take care of the safety of its passengers?",
  hide_user_panel: "Hide user panel",
  show_user_panel: "Show user panel",
  //I
  Inmediate: "Inmediate",
  "Invalid credentials": "Invalid credentials",
  "In this view you can visualize the trips in course on the map":
    "In this view you can visualize the trips in course on the map",
  "In this map view you can visualize the waypoints origin and destination":
    "In this map view you can visualize the waypoints origin and destination",
  "In this form you can create a new trip for now or scheduled":
    "In this form you can create a new trip for now or scheduled",
  "If you need to adjust coordinates for a trip you can use the source and destination pointers for that purpose":
    "If you need to adjust coordinates for a trip you can use the source and destination pointers for that purpose",
  "In this table you can consult all current and scheduled trips associated with your corporate panel":
    "In this table you can consult all current and scheduled trips associated with your corporate panel",
  "ID VIAJE": "TRIP ID",
  ID: "ID",
  "ID USUARIO": "USER ID",
  Inactive: "Inactive",
  Invoice: "Service note",
  "Invoice to": "Service note to",
  "in a": "in a",
  "Ideal balance between cost control and self-management.":
    "Get thei deal balance between cost control and self-management.",
  "In this view you can visualize the parcels in course on the map":
    "In this view you can visualize the parcels in course on the map",
  "In this form you can create a new parcel for now or scheduled":
    "In this form you can create a new parcel for now or scheduled",
  "If you need to adjust coordinates for a parcel you can use the source and destination pointers for that purpose":
    "If you need to adjust coordinates for a parcel you can use the source and destination pointers for that purpose",
  "in corporate panels": "in corporate panels",
  //Q
  "quick, confortable and secure way": "quick, confortable and secure way",
  //L
  Login: "Login",
  Logout: "Logout",
  Landing: "Welcome",
  "Last name": "Last name",
  Link: "Link",
  "Live monitoring, accompaniment and assignment 24 hours a day of the day, 7 days a week with a response time from 3 to 7 minutes.":
    "live monitoring, accompaniment and assignment 24 hours a day of the day, 7 days a week with a response time from 3 to 7 minutes.",
  "Lo sentimos, Ridery no presta servicio en esa dirección de origen":
    "We are sorry, Ridery does not have service in that origin address",
  //M
  "More information": "More information",
  "Monitor your trips": "Monitor your trips",
  MONTO: "AMOUNT",
  "Manage your trips": "Manage your trips",
  Model: "Model",
  Multistop: "Multistops",
  multistop_quoted: "Quoted multistops",
  multistop_quoted_tooltip:
    "In this view you can review the quoted multisops, approve or deny. Every multitop expires 15 min before the set date",
  multi_stop_new: "New multistop quote",
  multistop_delete_modal: "Would you like to delete the quote ?",
  multistop_total_stop: "Stop total",
  multistop_time_stop: "Total time",
  multistop_distance_stop: "Total distance",
  multistop_new_map_tooltip:
    "In this map view you can review the multistop stops",
  multiStopNew: "New multistop",
  multistop_add: "Add",
  multistop_add_tooltip: "In this form you can stops to a multistop trip",
  multistop_stop: "Stop",
  multistop_origin: "Origin",
  multistop_full_1: "You've reached the maximum of allowed stops",
  multistop_full_2: "You can review the stop list and summary",
  multistop_list: "Stops list",
  multistop_list_tooltip:
    "In this table you can review the stops list of a multistop trip",
  multistop_total_resume: "Multistop summary",
  multistop_total_resume_tooltip:
    "In this view you can review the summary of the multistop",
  multistop_total_quote: "Quote multistop",
  multi_stop: "Multistop",
  multistop_show_quote_title: "Quote detail (Multistop)",
  multistop_show_quote_title_tooltip:
    "In this view you can see the details of multistop quote",
  multistop_approve: "Approve multistop quote",
  multistop_map_view: "Trip map (Multistop)",
  multistop_quote_success: "Multistop quote success",
  MultistopShow: "Multistop trip detail",
  manual_top_up_request: "Recargas manuales",
  manual_top_up_request: "Manual top ups",
  manual_top_up_pending_table: "Manual top ups waiting for validation",
  manual_top_up_pending_table_tooltip:
    "In this view you can consult your manual top ups waiting for validation",
  manual_payments_pending_see_details: "See details",
  //N
  "New Trip": "New Trip",
  "Not corporate": "Not corporate",
  Name: "Name",
  "Nombre y apellido": "Full name",
  "No driver": "No driver",
  NAME: "NAME",
  NOMBRE: "NAME",
  Note: "Note",
  "Not approved": "Not approved",
  "NON FISCAL": "NON FISCAL",
  "Monitoreo (Viajes) Teléfono": "Monitor (Trips) Phone",
  "Monitoreo (Viajes) Correo electrónico": "Monitor (Trips) Email",
  "must accept the request in Ridery's user app":
    "must approve the request in Ridery's user app",
  NOTA: "NOTE",
  "New Parcel": "New Parcel",
  no: "No",
  //O
  "Origin address": "Origin address",
  "Our clients": "Our clients",
  "Our sales team is capacitated to show you a demo with the whole potential of the panel":
    "Get to know in depth the benefits we have for your company, collaborators and clients.",
  "Obligatory field": "Obligatory field",
  Operator: "Operator",
  Overdraft: "Overdraft",
  "ORIGIN ADDRESS": "ORIGIN ADDRESS",
  "ORIGEN DE TRANSACCIÓN": "TRANSACTION ORIGIN",
  OVERDRAFT: "OVERDRAFT",
  "Optimize your time": "Optimize your time",
  "Our drivers must pass a selection process of 4 stages:":
    "We ensure a robust driver and vehicle certification process, and additionally, our monitoring team is always available to attend to any need that may arise.",
  "Our operators are 24 hours for you! Communicate for the following means for any incident or inconvenience presented:":
    "You can contact 24/7 with our operators through two service channels: Whatsapp +58 (412) 604-29-80 or the email viaje@ridery.app",
  "Our trips are calculated based on a fixed rate per fleet selected being able to have various surcharges (time of day, traffic conditions, etc) if applicable.":
    "We take as a basis a fixed rate per selected fleet, on which variations may apply depending on the schedule, traffic, weather, among other factors.",
  //P
  "Please sign-in to your account and manage your trips":
    "Please sign-in to your account and manage your trips",
  Password: "Password",
  Profile: "Profile",
  "Please check your email": "Please check your email",
  PasswordConfirm: "Password confirm",
  "Password reset": "Password reset",
  Provider: "Provider",
  Payroll: "Payroll",
  PROVIDER: "PROVIDER",
  PHONE: "PHONE",
  Print: "Print",
  "Psychotechnical evaluation": "Integral psychotechnical evaluation",
  "Psycho-technical evaluation, vehicle certification (aesthetics and mechanical), up-to-date legal documentation and final exam of app approval":
    "Psycho-technical evaluation, vehicle certification (aesthetics and mechanical), up-to-date legal documentation and final exam of app approval",
  "Plate number": "Plate number",
  provider_name: "Provider name",
  Phone: "Phone",
  PARCELS: "PARCELS",
  "Parcel Type": "Parcel Type",
  "Parcel now": "Instant",
  "Parcel responsible user": "Parcel responsible user",
  "Parcel history": "Parcels history",
  "PARCEL ID": "ID DE ENVÍO",
  "ID DE ENVÍO": "PARCEL ID",
  parcel_receives_tooltip: "This person will be contacted by the driver",
  parcel_receiver: "Who receives the parcel?",
  parcel_textarea: "Additional parcel details",
  parcel_textarea_error:
    "Additional details must be 150 characters long or less",
  parcel_create: "Create parcel",
  parcel_created: "Envío creado exitosamente",
  parcel_cancel: "Cancel parcel",
  parcel_cancel_modal: "Do you want to cancel the parcel?",
  parcel_details: "Parcel details",
  parcel_details_table: "Who receives the parcel",
  parcelHistory: "Parcel history",
  parcel_table_running_tooltip:
    "In this table you can consult all current and scheduled parcels associated with your corporate panel",
  Parcels: "Parcels",
  ParceltooltipHistoryUser:
    "In this view you can see the user's parcel history with the corporate panel",
  parcel_more_info: "Policies",
  ParcelGuide: "Parcel policies",
  parcel_created: "Parcel created successfully",
  parcel_package_size: "Package size",
  parcel_package_mass: "Package weight",
  parcel_package_examples: "You can send these types of packages:",
  parcel_view_map_title: "Parcel map",
  parcel_view_map_completed_tooltip:
    "In this view you can see the approximate route of a completed parcel between point of origin and destination",
  parcel_view_map_completed_tooltip2:
    "In this view you can visualize the map status of a current parcel (if applicable) and the points of origin and destination",
  parcel_cancelled: "Parcel cancelled successfully",
  parcel_cancelled_error: "Error cancelling parcel",
  parcel_multistop_add_tooltip:
    "In this form you can add stops to a multipstop parcel",
  parcel_multistop_list_tooltip:
    "In this table you can review the list of stops of a multistop parcel",
  person_is_contact: "This person will be contacted by the driver",
  pago_movil_automatizado_tooltip:
    "Reload your corporate balance with our new pago móvil automatizado system",
  pago_movil_new: "New",
  ParcelMultistop: "Parcel multistops",
  parcelMultiStopNew: "New multistops parcel",
  parcelMultiStopShow: "Parcel multistops detail",
  parcel_continue: "Continue",
  parcel_i_send: "Sends",
  parcel_i_receive: "Receives",
  parcel_textarea_1_send: "Instructions at the pickup",
  parcel_textarea_1_receive: "Instructions at the destination",
  parcel_data_person_send: "Data of the person receiving",
  parcel_data_person_receive: "Data of the person sending",
  parcel_user_full_name: "Name and last name",
  parcel_textarea_2_send: "Instructions at the destination",
  parcel_textarea_2_receive: "Instructions at the pickup",
  parcel_textarea_package: "Package description",
  parcel_package: "Package",
  parcel_package_image: "Package image",
  parcel_upload_image: "Upload image",
  parcel_prohibited_items: "Prohibited items",
  parcel_prohibited_items_shipments: "in our shipments",
  parcel_prohibited_fireamrs: "Firearms",
  parcel_prohibited_substances: "Illicit substances",
  parcel_prohibited_cash: "Cash",
  parcel_prohibited_waste: "Toxic waste",
  parcel_prohibited_lingots: "Bullion",
  parcel_prohibited_fireworks: "Fireworks",
  parcel_prohibited_insecticides: "Insecticides",
  parcel_shipment_summary: "Shipment summary",
  parcel_quote_origin: "Origin",
  parcel_not_defined_remittent: "Not defined",
  parcel_image_error_size: "The image must be below 5mb",
  parcel_image_error_required: "Image is required",
  parcel_quote_sender: "Peson who sends",
  parcel_quote_sender_phone: "Telephone",
  parcel_quote_instructions_on_origin: "Instructions at the origin",
  parcel_quote_destination: "Destination",
  parcel_quote_receiver: "Person who receives",
  parcel_quote_recoiver_phone: "Telephone",
  parcel_quote_instructions_on_destination: "Instructions at the destination",
  parcel_quote_package_photo: "Package photo",
  parcel_quote_package_description: "Package description",
  parcel_parcel: "Parcel",
  //R
  REQUESTS: "REQUESTS",
  "Running trips": "Running trips",
  "Realtime map": "Map",
  "Reset your password": "Reset your password",
  "Results per page": "Results per page",
  "REMAINING BALANCE": "REMAINING BALANCE",
  "Ridery's Corporate team": "Ridery's Corporate team",
  "Ridery Contact": "Ridery Contact",
  "Ridery Corporate": "Ridery Corporate",
  "Request a ride in less than 10 minutes.":
    "Request a ride in less than 10 minutes.",
  "Refresh table": "Refresh table",
  "Refresh map": "Refresh map",
  "Running parcels": "Running parcels",
  //Q
  "Quick quote": "Quick quote",
  //S
  Scheduled: "Scheduled",
  Started: "Started",
  Send: "Send",
  "Service type": "Service type",
  Search: "Search",
  "Search by...": "Search by...",
  STATUS: "STATUS",
  "SALDO RESTANTE": "REMAINING BALANCE",
  Status: "Status",
  SALDO: "BALANCE",
  SOBREGIRO: "OVERDRAFT",
  "Simultaneously move your collaborators or clients.":
    "Simultaneously move your collaborators or clients.",
  "Schedule a meeting with our team": "Schedule a meeting with us",
  Sobredemanda: "Surge",
  Service: "Service",
  SERVICIO: "SERVICE",
  special_trip: "Special trip",
  //T
  "Trip Type": "Trip Type",
  Telephone: "Telephone",
  "Travel now": "Travel now",
  "Tipo de viaje": "Trip type",
  "Tipo de usuario": "User type",
  "Tipo de servicio": "Service type",
  "Trip created successfully": "Trip created successfully",
  "TRIP ID": "TRIP ID",
  "Trip cancelled successfully": "Trip cancelled successfully",
  "Trip history": "Trip history",
  tooltipHistory:
    "In this view you can check the trip history, filter and export .xls",
  tooltipBalance:
    "In this view you can see the different operations on your balance",
  "Transaction origin": "Transaction origin",
  "TRANSACTION ORIGIN": "TRANSACTION ORIGIN",
  tooltipUsers1:
    "In this view you can check your payroll and make basic administration actions such as:",
  tooltipCorporatePanels1:
    "In this view you can check your corporate panels and make basic administration actions such as:",
  tooltipUsers2: "Add users, manage overdrafts, add balance, remove users",
  tooltipCorporatePanels2: "Add balance or view corporate panels",
  TELÉFONO: "PHONE",
  "Thank you for choosing us": "Thank you for choosing us",
  Tax: "Tax",
  "This receipt does not represent a fiscal invoice":
    "This service note does not represent a fiscal invoice",
  "Trip map": "Trip map",
  tooltipTripMap:
    "In this view you can see the approximate route of a completed trip between point of origin and destination",
  tooltipTripMap2:
    "In this view you can visualize the map status of a current trip (if applicable) and the points of origin and destination",
  "Trip ID": "Trip ID",
  Trips: "Trips",
  tooltipUserAssign:
    "In this view you can consult a given user assignations made from the panel",
  "Through our toolset": "Through our toolset",
  "To enter our fleet drivers must pass a 4-stage selection process":
    "to enter our Ridery fleet a driver must pass an exteneous 4-stage selection process",
  "Total users": "Total users",
  "Trip in couse": "Trip in couse",
  tooltipHistoryUser:
    "In this view you can see the user's trip history with the corporate panel",
  "This input is autocomplete": "This input is autocomplete",
  tooltipHistoryParcel:
    "In this view you can review the parcel's history of the panel",
  TripInfo: "Información",
  trip: {
    trip_resume: "Trip summary",
  },
  topUps: "Top Ups",
  tooltipTopUps: "Here you can select a payment method to do a balance top up",
  //U
  USERS: "USERS",
  User: "User",
  Users: "Users",
  unique_id: "ID Viaje",
  user_first_name: "User name",
  "User type": "User type",
  "User name": "User name",
  USER: "USER",
  USUARIO: "USER",
  "User amount": "User amount",
  "USER ID": "USER ID",
  "Up-to-date legal documentation": "Up-to-date legal documentation",
  "User does not have sufficient balance or there isn't enough overdraft":
    "User does not have sufficient balance or there isn't enough overdraft",
  user_wallet: "User balance",
  user_found_db_ridery: "User found in Ridery's database",
  user_found_db_ridery_filling: "Automatically filling inputs",
  "ÚLTIMA PARADA": "ÚLTIMA PARADA",
  //V
  ViewMap: "View map",
  "View user": "View user",
  "View provider": "Ver provider",
  "View map": "View map",
  "View invoice": "View invoice",
  "Vehicle certification (aesthetics and mechanics)":
    "Aesthetic and mechanical review",
  Vehicle: "Vehicle",
  Vehicle: "Vehicle",
  variable_tariffs: "Our tariffs may vary",
  "View corporate panel": "View corporate panel",
  //W
  "Welcome!": "Welcome!",
  Waiting: "Waiting",
  "We've sent an email to": "We've sent an email to",
  "Welcome again": "Welcome again",
  "Waiting for approval": "Waiting for approval",
  "We are the app that modernizes your business mobility":
    "We are the app that modernizes your business mobility",
  "We have an extensive fleet of vehicles, ranging from the most economical to passenger buses in the whole national territory.":
    "we have an extensive fleet of vehicles, ranging from the most economical to passenger buses in the whole national territory.",
  "We have the following means for you to contact us. Our team will gladly assist you.":
    "Our team is willing to assist any requirement. We mainly have two channels to answer your queries or doubts.",
  "What should I do if I file an incident with my app?":
    "What should I do if I file an incident with my app?",
  //Y
  "You can log back in": "You can log back in",
  "You can create trips for your corporate users or for third-party users":
    "You can create trips for your corporate users or for third-party users",
  Yes: "Yes",
  "You've selected an invalid hour, previous to current time. Please select a different hour":
    "You've selected an invalid hour, previous to current time. Please select a different hour",
  "Yes! When requesting a trip through the Corporate Panel, select ''Non-Corporate User'', fill in the fields, you proceed to request the trip and that's it, your rider will arrive right away.":
    "Yes! When requesting a trip through the Corporate Panel, select ''Non-Corporate User'', fill in the fields, you proceed to request the trip and that's it, your rider will arrive right away.",
  "You can also make quick quotes": "You can also make quick quotes",
  Year: "Year",
  "you may search corporate users by:": "You may search corporate users by:",
  "You can create parcel for your corporate users or for third-party users":
    "You can create parcel for your corporate users or for third-party users",
  yes: "Yes",

  // headers
  "LAST STOP": "LAST STOP",
  "ÚLTIMA PARADA": "LAST STOP",

  STOPS: "STOPS",
  PARADAS: "STOPS",

  SERVICE: "SERVICE",
  SERVICIO: "SERVICE",

  STOP: "STOP",
  PARADA: "STOP",

  "STOP COST": "STOP COST",
  "COSTO DE PARADA": "STOP COST",

  FEE: "FEE",
  RECARGO: "FEE",

  "TOTAL STOP": "TOTAL STOP",
  "TOTAL PARADA": "TOTAL STOP",

  ACTION: "ACTION",
  ACCIÓN: "ACTION",

  "STOP QUANTITY": "STOP QUANTITY",
  "CANTIDAD PARADAS": "STOP QUANTITY",

  "ESTIMATED TIME": "ESTIMATED TIME",
  "TIEMPO ESTIMADO": "ESTIMATED TIME",

  "ESTIMATED DISTANCE": "ESTIMATED DISTANCE",
  "DISTANCIA ESTIMADA": "ESTIMATED DISTANCE",

  "MULSTISTOP COST": "MULSTISTOP COST",
  "COSTO MULTIPARADA": "MULSTISTOP COST",

  "Download PDF": "Download PDF",
  "Service Note": "Service Note",
  "Download Quote": "Download Quote",
  "Service Quote to": "Service Quote to",
  "Service Quote": "Service Quote",
  SURGE: "SURGE",
  TOTAL: "TOTAL",
  "Note: This quote represents a referential value of the trip":
    "Note: This quote represents a referential value of the trip",
  "the value of the trip is subject to change":
    "the value of the trip is subject to change",
  "PARADA FINAL": "FINAL STOP",

  Add: "Add",
  Remove: "Remove",
  "Promo Code": "Promo Code",
  "Add Promo Code": "Add Promo Code",
  "The promo code has expired or is invalid":
    "The promo code you entered is not valid",
  "Promo code applied successfully": "Promo code applied successfully",
  "Error getting estimated fare with promo code":
    "Error getting estimated fare with promo code",

  "trip description": "Trip description",
  "TRIP DESCRIPTION": "TRIP DESCRIPTION",
  "Add Description": "Add Description",
  "Add trip description": "Add trip description",
  Completado: "Completed",
  "ID VIAJE": "TRIP ID",
  DESCRIPTION: "DESCRIPTION",

  "Exporting parcels": "Exporting parcels",
  "Exporting trips": "Exporting trips",
  "You can download the export file": "You can download the export file",
  "The export file has": "The export file has",
  expired: "expired",
  "Procesando páginas para archivo Excel": "Processing pages for Excel file",
  "Procesando página": "Processing page",
  of: "of",
  "Sorry, there was an error": "Sorry, there was an error",
  Close: "Close",
  "The export file has": "The export file has",
  Download: "Download",

  "Exporting payments": "Exporting payments",
  "Pago móvil automatizado": "Automated pago móvil",
  "FACTURA PERSONALIZADA": "CUSTOM INVOICE",
  MONEDA: "CURRENCY",
  TIPO: "TYPE",
  "FECHA ENVIADO": "DATE SENT",
  "FECHA DE RECARGA": "RECHARGE DATE",

  DESCRIPCIÓN: "DESCRIPTION",
  "Cancel Reason": "Cancel Reason",
  "Select one of the following reasons to cancel this trip":
    "Select one of the following reasons to cancel this trip",
  Confirm: "Confirm",
  Total: "Total",

  "Enter current password": "Enter current password",
  "Enter new password": "Enter new password",
  "Confirm new password": "Confirm new password",
  "Incorrect password": "Incorrect password",
  "Change password": "Change password",
  "Your password has been saved successfully":
    "Your password has been saved successfully",
  "Passwords do not match": "Passwords do not match",
  Continue: "Continue",

  "Recover password": "Recover password",
  "To recover your password, enter your email":
    "To recover your password, enter your email",
  GOBACK: "GO BACK",
  "with instructions to reset your password. Check your inbox, including the spam folder":
    "with instructions to reset your password. Check your inbox, including the spam folder",
  "Check your email": "Check your email",
  "Set the new password and its confirmation":
    "Set the new password and its confirmation",
  PasswordNewConfirm: "Confirm new password",
  "New Password": "New Password",
  ResetPassword: "Reset Password",
  "We're sorry!": "We're sorry!",
  "The link has expired. Request a new link to reset your password":
    "The link has expired. Request a new link to reset your password",
  "Back to the start": "Back to the start",
  "Error resetting password": "Error resetting password",
  "An error occurred, please try again later":
    "An error occurred, please try again later",
  "The email is not valid": "The email is not valid",
  "Passwords must match": "Passwords must match",

  "Exporting payroll": "Exporting payroll",
  "Esperando aprobación": "Waiting for approval",
  Inactivo: "Inactive",
  "Charge of this parcel": "Charge of this parcel",
  NOTE: "NOTE",
  ForgotPassword: "Forgot Password",
  "Select payment method": "Select payment method",
  Available: "Available",
  "Amount is required": "Amount is required",
  "Amount must be greater than 0": "Amount must be greater than 0",
  "Amount to add": "Amount to add",
  "Amount to pay": "Amount to pay",
  "Make the payment to": "Make the payment to",
  Bank: "Bank",
  "Bank copied!": "Bank copied!",
  "RIF copied!": "RIF copied!",
  "Phone number": "Phone number",
  "Phone number copied!": "Phone number copied!",
  "Enter the details of the pago móvil made":
    "Enter the details of the pago móvil made",
  Operador: "Operator",
  "Payment date": "Payment date",
  "Last 4 reference digits": "Last 4 reference digits",
  Back: "Back",
  "Text copied": "Text copied",
  "not found in Ridery's database": "not found in Ridery's database",
  "You can review the data of": "You can review the data of",
  "and try again or request assistance": "and try again or request assistance",
  "Request Assistance": "Request Assistance",
  "Review data": "Review data",
  "Top up pending!": "Top up pending!",
  "Your manual top up has been sent to Ridery's database":
    "Your manual top up has been sent to Ridery's database",
  "Top up successful!": "Top up successful!",
  Added: "Added",
  "to your corporate balance of a pago móvil of Bs":
    "to your corporate balance of a pago móvil of Bs",
  "to your corporate balance from a payment":
    "to your corporate balance from a payment",
  "Corporate balance": "Corporate balance",
  "We're sorry!": "We're sorry!",
  "Account holder": "Account holder",
  "Account holder copied!": "Account holder copied!",
  "Email copied!": "Email copied!",
  "Memo number": "Memo number",
  "Memo number copied": "Memo number copied!",
  "Enter the details of the zelle made": "Enter the details of the zelle made",
  "Enter your payment date": "Enter your payment date",
  "Amount sent": "Amount sent",
  "Zelle owner": "Zelle owner",
  "The zelle owner is the person or entity that made the payment":
    "The zelle owner is the person or entity that made the payment",
  "Do you want a custom invoice?": "Do you want a custom invoice?",
  "Is this top up related to a proform?":
    "Is this top up related to a proform?",
  "Proforma number": "Proforma number",
  "Make your payment using the following coordinates and instructions":
    "Make your payment using the following coordinates and instructions",
  Reference: "Reference",
  "Send your payment receipt and related information to process the transaction":
    "Send your payment receipt and related information to process the transaction",
  "email to send invoice": "email to send invoice",
  No: "No",
  "payment receipt": "payment receipt",
  Recharge: "Recharge",
  "BALANCE HISTORY": "BALANCE HISTORY",
  "MANUAL TOP UPS": "MANUAL TOP UPS",
  "You've selected an invalid time. Please select a time that is at least 15 minutes from now.":
    "You've selected an invalid time. Please select a time that is at least 15 minutes from now.",
  "You've selected an invalid time. Please create a new quote is at least 10 minutes from now.":
    "You've selected an invalid time. Please create a new quote is at least 10 minutes from now.",

  "The link has expired. Request a new link to reset enter in assist mode":
    "The link has expired. Request a new link to reset enter in assist mode",
  "Welcome to": "Welcome to",
  "Usa tus credenciales del admin y accede a la cuenta":
    "Use your admin credentials and access the panel",
  "Assist Mode": "Assist Mode",

  "Exporting payroll": "Exporting payroll",
  "Esperando aprobación": "Waiting for approval",
  Inactivo: "Inactive",

  tooltipCredit:
    "Corporate credits are an advance in your balance so you can continue making trips in the panel, contact your executive for more information",
  Credits: "Credits",
  "Billed amount": "Billed amount",
  "PAY ADVANCE": "PAY ADVANCE",
  day: "day",
  days: "days",
  Missing: "Missing",
  Expired: "Expired",
  Expires: "Expires",

  "Pago exitoso!": "Successful payment!",
  "Was credited": "Was credited",
  "to your credit of a pago móvil of Bs":
    "to your credit of a pago móvil of Bs",
  "Credit balance": "Credit balance",
  "Exporting corporate credits": "Exporting corporate credits",
  "This is the amount credited to the account for the credit":
    "This is the amount credited to the account for the credit",
  ID: "ID",
  "AMOUNT ADVANCED": "AMOUNT ADVANCED",
  "AMOUNT PAID": "AMOUNT PAID",
  "EXPIRATION DATE": "EXPIRATION DATE",
  STATUS: "STATUS",
  ACTION: "ACTION",
  "Credit Detail": "Credit Detail",
  "Advance data": "Advance data",
  "Approval date": "Approval date",
  "Expiration date": "Expiration date",
  "Paid amount": "Paid amount",
  "Amount added": "Amount added",
  "Credit data": "Credit data",
  "Credit movements": "Credit movements",
  "Email to send invoice": "Email to send invoice",
};
